<template>
  <transition name="dialogMask-show">
    <div class="dialogMask" v-if="dialogVisible">
      <div class="dialogWrap">
        <div class="dialogTopWrap">
          <span class="closeBtn" @click="closeDialog"></span>
          <div class="logisticsImg" v-if="!!expressImg[info.com]">
            <img :src="require(`@/assets/express/${expressImg[info.com]}.png`)" alt="" />
          </div>
          <div class="logisticsIntro">
            <p class="name">{{ expressCompany[info.com] || "未知物流公司" }}</p>
            <p class="id">{{ info.nu || "--" }}</p>
          </div>
        </div>
        <div class="dialogConWrap">
          <div class="myAddr">
            <span class="sign">收</span>
            <p class="addr">[收货地址]{{ addrTxt }}</p>
          </div>
          <p class="tips" v-if="loading">加载中...</p>
          <p class="tips" v-if="!loading && (!info.data || !info.data.length)">
            暂无物流信息
          </p>
          <div class="infoScroll" v-if="info.data">
            <div
              class="infobar"
              v-for="(item, index) in info.data"
              :key="'infobar' + index"
            >
              <p class="time">{{ item.date }}<br />{{ item.time }}</p>
              <div class="state">
                <span class="sign"></span>
              </div>
              <div class="infotxt">
                <!-- 签收状态 (0在途，1揽收，2疑难，3签收，4退签，5派件，6退回，7转投) -->
                <p v-if="item.status">
                  {{ logisticsState(item.status, index) }}正在派送
                </p>
                <p v-if="!!item.contextArr">
                  <template v-for="(txtObj, index2) in item.contextArr">
                    <span :key="'contextArr' + index2" v-if="!!txtObj.txt">{{
                      txtObj.txt
                    }}</span>
                    <span
                      :key="'contextArr' + index2"
                      v-if="!!txtObj.no"
                      class="no"
                      :id="'copyContactNo' + index + '-' + index2"
                      :data-clipboard-text="txtObj.no"
                      @click="
                        copyContactNo('copyContactNo' + index + '-' + index2)
                      "
                      >{{ txtObj.no }}</span
                    >
                  </template>
                </p>
                <p v-else>
                  {{ item.context }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import expressCompany from "@/utils/expressCompany.js";
//  物流图片对应
const expressImg = {
  zhongtong: "zhongtong",
  zhongtongguoji: "zhongtong",
  zhongtongkuaiyun: "zhongtong",
  huitongkuaidi: "baishi",
  baishiwuliu: "baishi",
  ems: "ems",
  emsguoji: "ems",
  emsinten: "ems",
  emsbg: "ems",
  emsen: "ems",
  bjemstckj: "ems",
  emswuliu: "ems",
  debangkuaidi: "debang",
  debangwuliu: "debang",
  shentong: "shentong",
  stosolution: "shentong",
  shunfeng: "shunfeng",
  shunfengkuaiyun: "shunfeng",
  shunfenghk: "shunfeng",
  tiantian: "tiantian",
  youzhengguonei: "youzheng",
  youzhengbk: "youzheng",
  yuantong: "yuantong",
  yuantongkuaiyun: "yuantong",
  yuantongguoji: "yuantong",
  yunda: "yunda",
  yundakuaiyun: "yunda",
  zhaijisong: "zhaijisong"
};
export default {
  name: "logisticsDialog",
  props: ["dialogShow", "id", "addr"],
  data() {
    return {
      dialogVisible: false,
      info: {},
      loading: false,
      addrTxt: "",
      expressCompany: expressCompany || {},
      expressImg: expressImg
    };
  },
  watch: {
    dialogShow(val) {
      this.dialogVisible = val;
      if (val) this.getLogistDetail();
    },
    addr(val) {
      if (!val) {
        this.addrTxt = "";
      } else {
        this.addrTxt =
          val.provinceName +
          val.cityName +
          val.districtName +
          " " +
          val.detailAddress +
          " " +
          val.mobile;
      }
    }
  },
  methods: {
    //  获取物流详情
    getLogistDetail() {
      if (!this.id) {
        this.$toast({
          message: "暂无物流详情",
          position: "bottom"
        });
      }
      this.loading = true;
      let lastInfo = sessionStorage.getItem(`LOGISTICS${this.id}`);
      if (!!lastInfo) {
        lastInfo = JSON.parse(lastInfo);
        if (new Date().getTime() - lastInfo.time < 300000) {
          this.info = lastInfo.info;
          this.loading = false;
          return;
        }
      }
      this.request
        .get("/api/scm/order/shipping/query", {
          bizId: this.id
        })
        .then((res) => {
          if (res && res.length) {
            this.info = res[0] || {};
            if (this.info.data) {
              let isMob = /(((\+?86)|(\(\+86\)))?([1][0-9]{10}))/g;
              this.info.data.forEach((item) => {
                //  时间格式
                let time = new Date(item.ftime);
                item.date =
                  this.global.satisfyFull(time.getMonth() + 1, 2) +
                  "-" +
                  this.global.satisfyFull(time.getDate(), 2);
                item.time =
                  this.global.satisfyFull(time.getHours(), 2) +
                  ":" +
                  this.global.satisfyFull(time.getMinutes(), 2);
                if (!!item.context && item.context.match(isMob)) {
                  let tmpArry = [],
                    mobArry = item.context.match(isMob),
                    length = item.context.length,
                    curIndex = 0;
                  mobArry.forEach((mob) => {
                    let sI = item.context.indexOf(mob);
                    if (sI >= curIndex) {
                      let txt = item.context.substring(curIndex, sI);
                      curIndex = sI + mob.length;
                      tmpArry.push({
                        txt: txt || ""
                      });
                      tmpArry.push({
                        no: mob
                      });
                    }
                  });
                  if (curIndex < length - 1) {
                    tmpArry.push({
                      txt: item.context.substring(curIndex) || ""
                    });
                  }
                  item.contextArr = tmpArry;
                }
              });
            }
            //  缓存
            sessionStorage.setItem(
              `LOGISTICS${this.id}`,
              JSON.stringify({
                info: this.info,
                time: new Date().getTime()
              })
            );
          } else {
            this.$toast({
              message: "暂无物流详情",
              position: "bottom"
            });
          }
          this.loading = false;
        });
    },
    // 物流状态
    logisticsState(state, index) {
      // 签收状态 (0在途，1揽收，2疑难，3签收，4退签，5派件，6退回，7转投)
      let txt = "";
      switch (state) {
        case 1:
          txt = "已揽收";
          break;
        case 2:
          txt = "疑难件";
          break;
        case 3:
          txt = "已签收";
          break;
        case 4:
          txt = "已退签";
          break;
        case 5:
          txt = !index ? "正在派件" : "已派件";
          break;
        case 6:
          txt = "已退回";
          break;
        case 7:
          txt = "已转投";
          break;
      }
      return txt;
    },
    //  复制联系电话
    copyContactNo(id) {
      let clipboard = new this.clipboard("#" + id);
      clipboard.on("success", () => {
        this.$toast({
          message: "联系电话已复制至剪贴板",
          position: "bottom"
        });
      });
    },
    closeDialog() {
      this.$emit("close", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogWrap {
  width: 650px;
}
.dialogTopWrap {
  position: relative;
  border-bottom: 1px solid #e7e8ea;
  height: 120px;
  padding: 0 70px 0 20px;
  display: flex;
  align-items: center;
  .closeBtn {
    width: 50px;
    height: 50px;
    display: block;
    background: url(~@/assets/icons/close.png) center / 20px no-repeat;
    position: absolute;
    top: 8px;
    right: 5px;
  }
  .logisticsImg {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border: 10px;
    margin-right: 25px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .logisticsIntro {
    color: #333;
    .name {
      line-height: 38px;
      font-size: 30px;
    }
    .id {
      line-height: 32px;
      font-size: 24px;
    }
  }
}
.dialogConWrap {
  height: 55vh;
  overflow: hidden;
  padding: 48px 0 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  .myAddr {
    padding: 0 30px 20px 98px;
    display: flex;
    color: #333;
    position: relative;
    .sign {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: block;
      background: #ff583d;
      color: #fff;
      font-size: 24px;
      line-height: 36px;
      margin-top: 10px;
      margin-right: 15px;
      text-align: center;
    }
    .addr {
      flex: 1;
      min-height: 64px;
      line-height: 32px;
      font-size: 22px;
    }
  }
  .infoScroll {
    flex: 1;
    overflow-y: auto;
  }
  .tips {
    text-align: center;
    color: #666;
    font-size: 28px;
    line-height: 1;
    padding-top: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    /* prettier-ignore */
    width: 1PX;
    bottom: 0;
    left: 114px;
    top: 75px;
    background: #dadbdd;
    z-index: 0;
    transform: scaleX(0.5);
  }
}
.infobar {
  padding: 44px 30px 0 96px;
  min-height: 56px;
  display: flex;
  color: #999;
  line-height: 30px;
  font-size: 24px;
  position: relative;
  .time {
    position: absolute;
    min-width: 78px;
    text-align: center;
    right: calc(100% - 95px);
    top: 30px;
  }
  .state {
    display: block;
    width: 36px;
    height: 36px;
    position: relative;
    margin-right: 15px;
    .sign {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #dadbdd;
      border-radius: 50%;
      width: 13px;
      height: 13px;
    }
  }
  .infotxt {
    flex: 1;
    line-height: 36px;
    font-size: 24px;
    .contactNo {
      display: inline-block;
    }
    .no {
      display: inline-block;
      color: #ff583d;
    }
  }
  &:first-child {
    color: #333;
    padding-top: 24px;
    .state .sign {
      background: #ff583d;
    }
    .time {
      top: 10px;
    }
  }
  &.checked {
    .state .sign {
      background: #ff583d url(~@/assets/icons/gou.png) center / 28px no-repeat;
      width: 100%;
      height: 100%;
    }
  }
}
</style>