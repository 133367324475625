<template>
  <div id="orderDetail" class="fixedMode">
    <div class="mainScroll">
      <div class="orderState">
        {{ showOrderState(orderDetail.orderStatus) }}
      </div>
      <div class="partBg" v-if="virtualFlag!=1">
        <div class="addrWrap loading" v-if="!addrObj.id">
          <p class="name gapLoad">--</p>
          <p class="addr gapLoad">--</p>
        </div>
        <div class="addrWrap" v-else>
          <p class="name">
            {{ addrObj.name }}
            <span class="mobile">{{ addrObj.mobile }}</span>
          </p>
          <p class="addr">
            {{
              addrObj.provinceName +
              addrObj.cityName +
              addrObj.districtName +
              " " +
              addrObj.detailAddress
            }}
          </p>
        </div>
      </div>
      <div class="partBg goodsParam">
        <orderListGoods
          :isLoading="!goods.skuId"
          :buyCount="goods.buyNum"
          :imgUrl="goods.imgUrl"
          :goodsInfo="goods.goodsInfo"
          :skuAttr="goods.attribute"
          :createdTime="orderDetail.createdTime"
        ></orderListGoods>
        <div class="orderOperation">
          <div class="virtualCode">
            <div class="code" v-if="virtualCode">
              <div class="lable">券码：</div>
              <div class="value">{{ virtualCode }}</div>
            </div>
            <div class="copyBtn" id="virtualCode"  v-if="virtualCode"   :data-clipboard-text="virtualCode" @click="copyVirtualCode('#virtualCode')">复制</div>
          </div>
          <button type="button" class="buybtn" @click="toGoodsDetail">
            再下一单
          </button>
        </div>
        <div class="paramBar">
          <span class="itemName">商品总价</span>
          <span class="itemCon"
            >￥
            <span class="num">{{
              showPrice(orderDetail.orderAmount)
            }}</span></span
          >
        </div>
        <div class="paramBar">
          <span class="itemName">商品运费</span>
          <span class="itemCon">￥ <span class="num">0.00</span></span>
        </div>
        <div class="paramBar" v-if="couponTitle">
          <span class="itemName">优惠券名称</span>
          <span class="itemCon highlight"
            ><span class="num" style="color: #000;">{{couponTitle}}</span></span
          >
        </div>
         <div class="paramBar">
          <span class="itemName">优惠券满减</span>
          <span class="itemCon highlight"
            >-￥ <span class="num">{{couponPriceStr?couponPriceStr:'0.00'}}</span></span
          >
        </div>
        <div class="paramBar">
          <span class="itemName">实付金融</span>
          <span class="itemCon highlight"
            >￥
            <span class="num">{{
              showPrice(orderDetail.actualAmount)
            }}</span></span
          >
        </div>
        <div class="paramBar">
          <span class="itemName">订单状态</span>
          <span class="itemCon">{{
            showOrderState(orderDetail.orderStatus)
          }}</span>
        </div>
      </div>

      <div class="partBg orderInfo">
        <p class="title">订单信息</p>
        <div class="paramBar">
          <p class="itemName">
            <span class="infoItem">订单编号: </span>{{ bizId }}
          </p>
          <span
            class="itemCon highlight"
            :data-clipboard-text="bizId"
            @click="copyOrderNo('#copyOrderNo')"
            id="copyOrderNo"
          >
            复制
          </span>
        </div>
        <div class="paramBar" v-if="!shippingNo == ''">
          <p class="itemName">
            <span class="infoItem">物流编号: </span>{{ shippingNo }}
          </p>
          <span
            class="itemCon highlight"
            :data-clipboard-text="shippingNo"
            @click="copyOrderNo('#shippingOrder')"
            id="shippingOrder"
          >
            复制
          </span>
        </div>
      </div>
    </div>
    <div class="bottomBtn">
      <button type="button" class="buybtn main" @click="toOrderList">
        返回订单列表
      </button>
      <div class="btnRight">
        <button
          type="button"
          class="buybtn"
          @click="cancelOrder"
          v-if="orderDetail.orderStatus === 0"
        >
          取消订单
        </button>
        <button
          type="button"
          class="buybtn"
          @click="toPay"
          v-if="orderDetail.orderStatus === 0"
        >
          继续付款
        </button>
        <button
          type="button"
          class="buybtn"
          @click="logisticsDialogShow = true"
          v-if="orderDetail.orderStatus > 2"
        >
          查看物流
        </button>
      </div>
      <!-- <button type="button" class="buybtn">修改地址</button> -->
    </div>
    <logisticsDialog
      :dialogShow="logisticsDialogShow"
      :id="bizId"
      :addr="addrObj"
      @close="logisticsDialogShow = false"
    ></logisticsDialog>
  </div>
</template>

<script>
// import aOrderGoods from "@/components/goods/aOrderGoods";
import orderListGoods from "@/components/goods/orderListGoods";
import logisticsDialog from "@/components/order/logisticsDialog";

export default {
  name: "orderDetail",
  data() {
    return {
      skuId: this.$route.query.skuId,
      bizId: this.$route.query.bizId,
      orderDetail: {},
      goods: {},
      addrObj: {},
      shippingNo: "",
      logisticsDialogShow: false, //  显示物流弹窗
      virtualCode: "",
      virtualFlag:'',
      couponPriceStr:'',
      couponTitle:''
    };
  },
  components: {
    orderListGoods,
    logisticsDialog,
  },
  created() {
    this.getOrderDetail();
  },
  mounted() {},
  methods: {
    //  获取订单详情
    getOrderDetail() {
      this.request
        .get("/api/scm/order/v2/queryDetailByUid", {
          bizId: this.bizId,
        })
        .then((res) => {
          if (res && res.order) {
            this.orderDetail = res.order || {};
            this.goods =
              (this.orderDetail.productDataList &&
                this.orderDetail.productDataList[0]) ||
              {};
            this.goods.goodsInfo = {
              name: this.goods.spuName,
              currentPrice: this.goods.skuPrice / 100, //  后端数据价格单位为分
            };
            // console.log(typeof(res.shippingOrder))
            // console.log(res.shippingOrder)
            // this.shippingOrder = res.shippingOrder;
            this.addrObj = res.orderAddress || {};
            if (res.shippingOrder.length > 0) {
              const { shippingNo } = res.shippingOrder[0];
              this.shippingNo = shippingNo;
            }
            const { productVirtualCodePoolVos,virtualFlag,couponPriceStr,couponTitle } = res;
            this.couponPriceStr =couponPriceStr;
            this.virtualFlag = virtualFlag;
            this.couponTitle = couponTitle;
            if (productVirtualCodePoolVos&&productVirtualCodePoolVos.length) {
              const { code } = productVirtualCodePoolVos[0];
              this.virtualCode = code;
            }
          }
        });
    },
    //  再来一单
    toGoodsDetail() {
      if (!this.orderDetail.sceneNo) return;
      this.$router.push({
        path: "/h/scm/mall/goodsDetail",
        query: {
          sceneNo: this.orderDetail.sceneNo,
          spuId: this.goods.spuId,
          repurchaseId: this.goods.skuId || null,
        },
      });
    },
    //  取消订单
    cancelOrder() {
      this.$dialog
        .confirm({
          message: "确定取消当前订单？",
          cancelButtonText: "再想想",
          confirmButtonText: "确定取消",
          closeOnPopstate: false,
        })
        .then(() => {
          // on confirm
          this.request
            .get("/api/scm/order/v2/orderCanceled", {
              bizId: this.bizId,
            })
            .then((res) => {
              if (res) {
                this.getOrderDetail();
                this.$toast({
                  message: "订单已取消",
                  position: "bottom",
                });
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    //  继续付款
    toPay() {
      this.goods.spuInfo = {
        name: this.goods.spuName,
      };
      sessionStorage.setItem(
        `${this.bizId}Order`,
        JSON.stringify(this.orderDetail)
      );
      sessionStorage.setItem(
        `spuBuy${this.goods.spuId}`,
        JSON.stringify(this.goods)
      );
      // 跳转订单页面
      this.$router.push({
        path: "/h/scm/order/orderPay",
        query: {
          sceneNo: this.orderDetail.sceneNo,
          spuId: this.goods.spuId,
          bizId: this.bizId,
          skuId: this.goods.skuId,
          from: "orderDetail",
        },
      });
    },
    //  复制订单号
    copyOrderNo(str) {
      var clipboard = new this.clipboard(str);
      clipboard.on("success", () => {
        this.$toast({
          message: "复制成功",
          position: "bottom",
        });
      });
      clipboard.on("error", () => {
        this.$toast({
          message: "抱歉，复制失败，您的手机可能不支持此功能。",
          position: "bottom",
        });
      });
    },
    copyVirtualCode(code){
      var clipboard = new this.clipboard("#virtualCode");
      clipboard.on("success", () => {
        this.$toast({
          message: "复制成功",
          position: "bottom",
        });
      });
      clipboard.on("error", () => {
        this.$toast({
          message: "抱歉，复制失败，您的手机可能不支持此功能。",
          position: "bottom",
        });
      });
    },
    copyShoppingNo() {
      var clipboard = new this.clipboard("#shippingOrder");
      clipboard.on("success", () => {
        this.$toast({
          message: "复制成功",
          position: "bottom",
        });
      });
      clipboard.on("error", () => {
        this.$toast({
          message: "抱歉，复制失败，您的手机可能不支持此功能。",
          position: "bottom",
        });
      });
    },
    //  显示价格
    showPrice(price) {
      if (!price) return "0.00";
      let tmp = String(price);
      return (
        (tmp.length < 3 ? "0" : tmp.substr(0, tmp.length - 2)) +
        "." +
        this.global.getNumLastDoubleDigit(price)
      );
    },
    //  显示订单状态
    showOrderState(state) {
      //0：未付款，1：已付款，2：用户取消订单, 3：已发货，4：用户确认收货，5：退货、退款
      let txt = "";
      switch (state) {
        case 0:
          txt = "买家未付款";
          break;
        case 1:
          txt = "买家已付款";
          break;
        case 2:
          txt = "订单已取消";
          break;
        case 3:
          txt = "卖家已发货";
          break;
        case 4:
          txt = "买家已签收";
          break;
        case 5:
          txt = "订单已退货、退款";
          break;
      }
      return txt;
    },
    //  跳转订单
    toOrderList() {
      this.$router.push({
        name: "orderList",
        query: {
          sceneNo: this.orderDetail.sceneNo,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fixedMode {
  background: #efefef;
}
.orderState {
  height: 178px;
  background: linear-gradient(to right, #f62300, #fa5421);
  display: flex;
  padding: 0 135px 0 110px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 30px;
  &::after {
    content: "";
    display: block;
    width: 142px;
    height: 100%;
    background: url(~@/assets/order/wallet.png) center / 100% auto no-repeat;
  }
}
.partBg {
  background: #fff;
  margin-bottom: 20px;
  padding: 0 30px;
  &.goodsParam {
    padding-top: 30px;
    padding-bottom: 5px;
  }
  &.orderInfo {
    padding-top: 20px;
    padding-bottom: 15px;
    .title {
      line-height: 48px;
      font-size: 30px;
    }
  }
}
.goodsWrap {
  margin-bottom: 20px;
}
.addrWrap {
  padding: 25px 0 20px 75px;
  background: url(~@/assets/icons/addr.png) 10px 35px / 35px auto no-repeat;
  .name {
    line-height: 40px;
    font-size: 26px;
    margin-bottom: 5px;
    .mobile {
      display: inline-block;
      color: #999;
      padding-left: 20px;
      font-size: 24px;
    }
  }
  .addr {
    min-height: 70px;
    color: #333;
    font-size: 24px;
    line-height: 30px;
  }
}
.orderOperation {
  display: flex;
  // justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  height: 78px;
  .virtualCode {
    width: 500px;
    height: 78px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    .code {
      height: 78px;
      display: flex;
      align-items: center;
      font-size: 24px;
      .lable {
        width: 110px;
        margin-right: 10px;
        font-size: 32px;
        font-weight: bolder;
      }
      .value {
        height: 78px;
        color: #ea3d39;
        line-height: 80px;
        font-size: 38px;
        font-weight: bolder;
      }
    }
    .copyBtn {
      height: 40px;
      width: 80px;
      border: 1px solid #ea3d39;
      border-radius: 50px;
      font-size: 24px;
      color: #ea3d39;
      background: #fff;
      text-align: center;
      line-height: 40px;
    }
  }
  .buybtn {
    display: inline-block;
    height: 50px;
    width: 148px;
    border: 1px solid #ea3d39;
    border-radius: 50px;
    font-size: 24px;
    color: #ea3d39;
    background: #fff;
  }
}
.paramBar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 26px;
  .infoItem {
    width: 170px;
    display: inline-block;
  }
  .itemCon {
    .num {
      font-size: 28px;
    }
    &.highlight {
      color: #ea3d39;
    }
    &.blue {
      color: #11a9ff;
    }
  }
}
.bottomBtn {
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background: #fff;
  .btnRight {
    display: inline-flex;
    align-items: center;
  }
  .buybtn {
    min-width: 178px;
    height: 58px;
    display: inline-block;
    border-radius: 58px;
    background: #fff;
    color: #333;
    font-size: 26px;
    margin-left: 24px;
    line-height: 1;
    /* prettier-ignore */
    border: 1PX solid #999;
    &.main {
      background: #ff583d;
      border-color: #ff583d;
      color: #fff;
      padding: 0 20px;
    }
  }
}
</style>